@import-normalize; /* bring in normalize.css styles */

body {
  margin: 0px;
  padding: 0px;
}

* {
  box-sizing: border-box;
}

input {
  outline: none;
}